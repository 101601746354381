import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Markdown from "react-markdown"
import Swipe from "swipejs"
import OpenApp from "open-app"
import { updateFavicon, isiPhoneX } from "../utils"
import { HOST } from "../config/index"
import Layout from "../components/layout"

export const query = graphql`
  query ArticleQuery($id: Int) {
    strapiArticle(strapi_id: { eq: $id }) {
      id
      title
      deeplink
      strapi_id
      slides {
        banner {
          url
          localFile {
            url
          }
        }
        content {
          data {
            content
          }
        }
      }
    }
  }
`

const Article = ({ data }) => {
  useEffect(() => {
    updateFavicon()
    new OpenApp({})
    ;(window as any).iswipe = new Swipe(document.getElementById("slider"), {
      continuous: false,
    })
    if ((window as any).imToken) {
      ;(window as any).imToken.callAPI("navigator.configure", {
        navigationStyle: "default",
      })
    }
  }, [])

  const article = data.strapiArticle
  const len = article?.slides?.length
  console.log(article?.slides)
  const isBuyButtonArticle = ["Article_48", "Article_51"].includes(article.id)
  const link =
    "Article_48" === article.id
      ? "https://shop42404838.m.youzan.com/wscgoods/detail/36cu0boa4jpem?dc_ps=3079051285115341831.200001"
      : "https://shop42404838.m.youzan.com/wscgoods/detail/2xj16xca3nkmm?dc_ps=3079051611566410761.200001"

  return (
    <Layout title={article.title}>
      <div id="slider" className="swipe">
        <div
          className="swipe-wrap"
          style={{ paddingBottom: isiPhoneX() ? 45 : 20 }}
        >
          {article.slides.map((t, idx) => {
            return (
              <div key={idx} className="swipe-page">
                <img
                  className="banner"
                  style={{ marginBottom: 0 }}
                  src={`${HOST}${t?.banner?.url}`}
                  alt="banner"
                />
                <div className="content-wrap">
                  <div
                    className={`content ${
                      !!article.deeplink ? "deeplink" : ""
                    }`}
                    style={{
                      height: `calc(37vh - ${isiPhoneX() ? "118px" : "73px"})`,
                      overflowY: "scroll",
                    }}
                  >
                    <Markdown source={t.content.data.content} />
                    {isBuyButtonArticle ? (
                      <a href={link} className="button">
                        立即购买
                      </a>
                    ) : (
                      article.deeplink && (
                        <a href={article.deeplink} className="button">
                          立即体验
                        </a>
                      )
                    )}
                  </div>

                  <div className="step-wrap">
                    <div className="progress-wrap">
                      <div
                        className="progress"
                        style={{ width: `${(100 * (idx + 1)) / len}%` }}
                      ></div>
                    </div>
                    <p>{`${idx + 1}/${len}`}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default Article
